import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { ensureHttps } from '@/utils/LinkUtility';
import { faCircleInfo, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Box, Button, Divider, Flex, NumberInput, Text, TextInput, Tooltip } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { useEffect } from 'react';
import classes from '../LinkCreationModal.module.css';
import { showNotification } from '@mantine/notifications';

export const ABTesting = () => {
  const [link, setLink] = useLinkSetupStore((state) => [state.link, state.setLink]);
  useEffect(() => {
    if (link.ab_testing_attributes.length === 0) {
      setLink({ ...link, ab_testing_attributes: [{ url: link.url, weight: 100 }] });
    }
  }, []);

  const handleEqualizeWeights = () => {
    const newAttributes = link.ab_testing_attributes.map((attribute) => {
      return { ...attribute, weight: 100 / link.ab_testing_attributes.length };
    });
    setLink({ ...link, ab_testing_attributes: newAttributes });
  };

  const checkUrlCount = () => {
    if (link.ab_testing_attributes.length < 10) {
      setLink({
        ...link,
        ab_testing_attributes: [...link.ab_testing_attributes, { url: '', weight: 0 }],
      });
    } else {
      showNotification({
        title: 'Error',
        message: 'You can not add more than 10 urls.',
        color: 'red',
      });
    }
  }

  // Add delete URL handler
  const handleDeleteUrl = (indexToDelete: number) => {
    // Don't allow deleting the first URL (original URL)
    if (indexToDelete === 0) {
      showNotification({
        title: 'Error',
        message: 'Cannot delete the original URL.',
        color: 'red',
      });
      return;
    }

    // Remove the URL from ab_testing_attributes
    const newAttributes = link.ab_testing_attributes.filter((_, index) => index !== indexToDelete);

    // Recalculate weights to maintain 100% total
    const remainingWeight = 100 - link.ab_testing_attributes[indexToDelete].weight;
    const weightMultiplier = 100 / remainingWeight;

    const adjustedAttributes = newAttributes.map(attr => ({
      ...attr,
      weight: Number((attr.weight * weightMultiplier).toFixed(2))
    }));

    setLink({ ...link, ab_testing_attributes: adjustedAttributes });
  };

  return (
    <Box
      p={16}
      style={{
        overflowY: 'auto',
      }}
      className={!link.is_ab_testing_enabled ? classes.disabled : ''}
    >
      <Flex align={'center'}>
        <Text
          fw={600}
          fz={14}
          style={{
            flexGrow: 1,
          }}
        >
          A/B Testing
        </Text>
        <Text fw={500} fz={12}>
          <FontAwesomeIcon
            icon={faCircleInfo}
            style={{ marginRight: 4, color: 'var(--mantine-color-yellow-6)' }}
          />
          Weight sum should be 100%
        </Text>
      </Flex>

      {link.ab_testing_attributes.map((abTest, index) => (
        <>
          <Flex align={'center'}>
            <TextInput
              style={{
                flexGrow: 1,
              }}
              label="URL"
              variant="filled"
              mt={12}
              placeholder="Variation URL"
              mr={12}
              disabled={index === 0}
              value={abTest.url}
              onChange={(event) => {
                const newAttributes = [...link.ab_testing_attributes];
                newAttributes[index].url = ensureHttps(event.currentTarget.value);
                setLink({ ...link, ab_testing_attributes: newAttributes });
              }}
            />
            <NumberInput
              color="yellow"
              mt={12}
              min={0}
              max={100}
              w={80}
              label="Weight (%)"
              onChange={(value) => {
                const newAttributes = [...link.ab_testing_attributes];
                newAttributes[index].weight = value;
                setLink({ ...link, ab_testing_attributes: newAttributes });
              }}
              value={abTest.weight}
            />
            <Tooltip label={index === 0 ? "Cannot delete original URL" : "Delete URL"}>
              <ActionIcon
                color="red"
                variant="subtle"
                mt={36}
                onClick={() => handleDeleteUrl(index)}
                disabled={index === 0}
              >
                <FontAwesomeIcon icon={faTrash} />
              </ActionIcon>
            </Tooltip>
          </Flex>
        </>
      ))}

      <Divider mt={16} mb={16} />
      <Flex justify={'space-between'}>
        <Button
          variant="subtle"
          color="primary"
          size="xs"
          onClick={checkUrlCount}
        >
          + Add new destination URL
        </Button>
        <Flex>
          <Button variant="outline" color="gray" size="xs" onClick={() => handleEqualizeWeights()}>
            Equalize
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};
